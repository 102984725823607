import React from 'react';

const Player = () => {
    return (
        <div>
            <span>Player Page</span>
        </div>
    )
};

export default Player;