import './App.css';

import AllRoutes from './Routes.js';

function App() {
  return (
      <AllRoutes/>
  );
}

export default App;