import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import {
    Player as PlayerView
} from './views';

const AllRoutes = () => {
    return (
        <Router>
            <div className="app">
                <Routes>
                    <Route
                        element={<PlayerView/>}
                        path="/player"
                        />
                </Routes>
            </div>
        </Router>
    )
};

export default AllRoutes;